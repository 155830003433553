import { KEY_ADMIN_INFO, KEY_APP_CLIENT_ID, KEY_LAST_ACTIVE_TIME, KEY_LOGIN_INFO, LOGIN_STORAGE_IV, LOGIN_STORAGE_SECRET, PASSWORD_IV, PASSWORD_SECRET } from './constants';

import BearerToken from '../types/bearer-token';
import CryptoUtil from './crypto-util';
import SignInResponse from '../types/sign-in-response';
import Token from '../types/token';
import Util from './util';

/**
 * Utility class for handling login-related operations, including secure storage of login data.
 * This class uses AES encryption with a secret key and initialization vector (IV) to protect sensitive login information.
 */
export default class LoginUtil {
	/**
	 * Internal storage instance used for storing login data.
	 * (Private to prevent direct modification)
	 */
	private static storage: Storage;

	/**
	 * Retrieves the storage instance (either `localStorage` or a custom implementation).
	 * This method uses lazy initialization to improve performance.
	 * @private
	 */
	private static getStorage(): Storage {
		if (!this.storage) {
			this.storage = localStorage;
		}

		return this.storage;
	}

	/**
	 * Save an item in storage after encrypting its value.
	 * @param key The key to store the data under.
	 * @param value The value to store.
	 */
	private static setItem(key: string, value: string): void {
		const encryptedKey = CryptoUtil.encryptAES(key, LOGIN_STORAGE_SECRET, LOGIN_STORAGE_IV);
		const encryptedValue = CryptoUtil.encryptAES(value, LOGIN_STORAGE_SECRET, LOGIN_STORAGE_IV);
		this.getStorage().setItem(encryptedKey, encryptedValue);
	}

	/**
	 * Retrieves an item from storage after decrypting its value.
	 * @param key The key to retrieve the data under.
	 * @returns The decrypted value from storage, or null if not found.
	 */
	private static getItem(key: string): string | null {
		const encryptedKey = CryptoUtil.encryptAES(key, LOGIN_STORAGE_SECRET, LOGIN_STORAGE_IV);
		const encryptedValue = this.getStorage().getItem(encryptedKey);
		const decryptedValue = encryptedValue ? CryptoUtil.decryptAES(encryptedValue, LOGIN_STORAGE_SECRET, LOGIN_STORAGE_IV) : null;

		return decryptedValue;
	}

	/**
	 * Removes an item from storage.
	 * @param key The key of the item to remove.
	 */
	private static removeItem(key: string): void {
		const encryptedKey = CryptoUtil.encryptAES(key, LOGIN_STORAGE_SECRET, LOGIN_STORAGE_IV);
		this.getStorage().removeItem(encryptedKey);
	}

	/**
	 * Clears all login details from storage.
	 */
	public static clearAll(): void {
		this.removeItem(KEY_LOGIN_INFO);
		this.removeItem(KEY_LAST_ACTIVE_TIME);
		this.removeItem(KEY_APP_CLIENT_ID);
	}

	/**
	 * Encrypts the provided value using AES encryption with the configured secret and IV.
	 * @param value The value to encrypt.
	 * @returns The encrypted string.
	 */
	public static encryptPassword(value: string): string {
		return CryptoUtil.encryptAES(value, PASSWORD_SECRET, PASSWORD_IV);
	}

	/**
	 * Saves the provided bearer token object in secure storage under the key KEY_LOGIN_INFO.
	 * The token is first converted to a JSON string before storing.
	 * 
	 * @param {BearerToken} bearerToken - The bearer token object to store.
	 */
	public static saveBearerToken(bearerToken: BearerToken) {
		this.setItem(KEY_LOGIN_INFO, JSON.stringify(bearerToken));
	}

	/**
	 * Retrieves the bearer token object from secure storage under the key KEY_LOGIN_INFO.
	 * If a token is found, it's parsed from the JSON string back to a BearerToken object.
	 * Otherwise, it returns undefined.
	 *
	 * @returns {BearerToken | undefined} The retrieved bearer token object or undefined if not found.
	 */
	public static getBearerToken(): BearerToken | undefined {
		const bearerTokenStr: string | null = this.getItem(KEY_LOGIN_INFO);
		const bearerToken: BearerToken | undefined = bearerTokenStr ? JSON.parse(bearerTokenStr) : undefined;

		return bearerToken;
	}

	/**
	 * Saves the provided sign-in response data by extracting and storing the admin name and bearer token.
	 * If the admin name is present, it is saved using the saveAdminInfo method.
	 * If bearer tokens are present, the first token in the array is saved using the saveBearerToken method.
	 *
	 * @param {SignInResponse} signInResponse - The sign-in response object containing admin information and bearer tokens.
	 */
	public static saveSignInResponse(signInResponse: SignInResponse) {
		signInResponse.admin_name && this.saveAdminInfo(signInResponse.admin_name);		
		signInResponse.bearer_tokens && this.saveBearerToken(signInResponse.bearer_tokens[0]);
	}

	/**
	 * Saves the provided admin information in secure storage under the key KEY_ADMIN_INFO.
	 * The information is first converted to a JSON string before storing.
	 *
	 * @param {string} adminInfo - The admin information to store.
	 */
	public static saveAdminInfo(adminInfo: string) {
		this.setItem(KEY_ADMIN_INFO, adminInfo);
	}

	/**
	 * Retrieves the admin information from secure storage under the key KEY_ADMIN_INFO.
	 * If found, the string is parsed from the JSON string.
	 * Otherwise, it returns undefined.
	 *
	 * @returns {string | undefined} The retrieved admin information string or undefined if not found.
	 */
	public static getAdminInfo(): string | undefined {
		const adminInfoStr: string | null = this.getItem(KEY_ADMIN_INFO);
		const adminInfo : string | undefined = adminInfoStr ? adminInfoStr : undefined;

		return adminInfo;
	}

	/**
	 * Retrieves the access token data from the stored bearer token, if available.
	 * This method first retrieves the bearer token and then extracts the 'access_token' property
	 * from the bearer token object. If any step fails, it returns undefined.
	 *
	 * @returns {Token | undefined} The access token data from the bearer token or undefined if not found.
	 */
	public static getAccessTokenData(): Token | undefined {
		const bearerToken: BearerToken | undefined = this.getBearerToken();
		const accessToken: Token | undefined = bearerToken ? bearerToken.access_token : undefined;

		return accessToken;
	}

	/**
	 * Retrieves the refresh token data from the stored bearer token, if available.
	 * This method first retrieves the bearer token and then extracts the 'refresh_token' property
	 * from the bearer token object. If any step fails, it returns undefined.
	 *
	 * @returns {Token | undefined} The refresh token data from the bearer token or undefined if not found.
	 */
	public static getRefreshTokenData(): Token | undefined {
		const bearerToken: BearerToken | undefined = this.getBearerToken();
		const refreshToken: Token | undefined = bearerToken ? bearerToken.refresh_token : undefined;

		return refreshToken;
	}

	/**
	 * Utility function to save the last active time of the user.
	 *
	 * This function stores the provided `dateTime` as the user's last active time in local storage.
	 *
	 * @param {Date} dateTime - The timestamp representing the user's last active time.
	 */
	public static saveLastActiveTime(dateTime: Date) {
		this.setItem(KEY_LAST_ACTIVE_TIME, JSON.stringify(dateTime));
	}


	/**
	 * Utility function to retrieve the user's last active time.
	 *
	 * This function attempts to retrieve the last active time stored in local storage.
	 *
	 * @returns {Date | undefined} - The user's last active time as a Date object, or undefined if not found.
	 */
	public static getLastActiveTime(): Date | undefined {
		const lastActiveTimeStr: string | null = this.getItem(KEY_LAST_ACTIVE_TIME);
		const lastActiveTime: Date | undefined = lastActiveTimeStr ? JSON.parse(lastActiveTimeStr) : undefined;

		return lastActiveTime;
	}

	/**
	 * Saves the provided client ID to local storage.
	 *
	 * This function uses localStorage to store the client ID under the key `KEY_APP_CLIENT_ID`.
	 *
	 * @param {string} clientId - The client ID to be saved.
	 */
	private static saveClientId(clientId: string) {
		this.setItem(KEY_APP_CLIENT_ID, clientId);
	}

	/**
	 * Retrieves the client ID from local storage, generating a new one if not found.
	 *
	 * This function first tries to retrieve the client ID from localStorage under the key `KEY_APP_CLIENT_ID`.
	 * If no client ID is found, it generates a new one using `Util.generateClientId` and saves it to local storage.
	 * Finally, it returns the retrieved or generated client ID.
	 *
	 * @returns {string} - The client ID retrieved from local storage or a newly generated one.
	 */
	public static getClientId(): string {

		let clientId: string | null = this.getItem(KEY_APP_CLIENT_ID)
		if (clientId === null) {
			clientId = Util.generateClientId();
			this.saveClientId(clientId);
		}

		return clientId || '';
	}

}
