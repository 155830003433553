import { Box, Tab } from '@mui/material';
import { INIT_ORGANIZATION, OrgStatus, TAB_ADMINS, TAB_BACK_UP, TAB_DETAIL, TAB_FEATURES, TAB_LOGS, orgTabs } from '../../../utils/constants';
import React, { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import AdminsTab from './AdminsTab';
import BackUpTab from './BackUpTab';
import CustomButton from '../../../components/CustomButton';
import DetailTab from './DetailTab';
import Feature from '../../../types/feature';
import FeaturesTab from './FeaturesTab';
import { FormikProps } from 'formik';
import LogsTab from './LogsTab';
import OrgTab from '../../../types/ui/org-tab';
import Organization from '../../../types/organization';
import OrganizationAdmin from '../../../types/organization-admin';
import OrganizationDetail from '../../../types/organization-detail';
import SaveIcon from '../../../components/CustomIcons/SaveIcon';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import { useOrganizationApi } from '../../../data/organization/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Provides a tabbed interface for creating or editing organizations.
 *
 * - Fetches organization data from the route location state on component mount.
 * - Determines edit/create mode based on the presence of an organization ID.
 * - Defines available organization detail tabs based on edit mode (some tabs might be hidden in create mode).
 * - Renders the appropriate tab content based on the selected tab (separate components for each detail section).
 *
 * @returns {JSX.Element} - The rendered organization detail screen component with its layout and functionalities.
 */
const OrganizationDetailScreen: React.FC = () => {

	const styles = useStyles();
	const { t } = useTranslation();
	const location = useLocation();
	const [selectedTab, setSelectedTab] = React.useState(TAB_DETAIL);
	const orgApi = useOrganizationApi();
	const orgInitialValue: Organization = location.state || INIT_ORGANIZATION;
	const [organization, setOrganization] = useState<Organization>(orgInitialValue);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const formikRef = React.createRef<FormikProps<OrganizationDetail>>();
	const [isValid, setIsValid] = useState<boolean>(false);
	const [isReadMode, setReadMode] = useState<boolean>(true);

	/**
	 * Fetches and sets the organization details on component mount or when `orgInitialValue` changes.
	 * 
	 * @param {Organization | undefined} orgInitialValue - Initial organization details object.
	 */
	useEffect(() => {
		handleInitialOrganization(orgInitialValue);
		setSelectedTab(TAB_DETAIL);
		setReadMode(Boolean(orgInitialValue.details.id));
	}, [orgInitialValue]);

	/**
	 * Handles initial organization data processing based on the `orgInitialValue` prop.
	 * 
	 * @param {Organization | undefined} orgInitialValue - Optional initial organization details object.
	 */
	const handleInitialOrganization = (orgInitialValue?: Organization) => {
		if (orgInitialValue && orgInitialValue.details) {
			if (orgInitialValue.details.id && !orgInitialValue.isFetched) {
				orgApi.fetchOrganizationDetail(orgInitialValue.details.id).then((data) => {
					if (data) {
						setOrganization(data);
					}
				});
			} else {
				setOrganization(orgInitialValue);
				setIsEdit(false);
			}
		}
	};

	/**
	 * Handles changes in the selected tab.
	 * Updates the state variable `value` to reflect the newly selected tab.
	 * 
	 * @param {React.SyntheticEvent} event - The event object triggered by the tab change.
	 * @param {string} newValue - The ID of the newly selected tab.
	 */
	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue);
		if (newValue !== TAB_DETAIL && formikRef.current) {
			handleOrgDetailChange(formikRef.current.values);
		}
	};

	/**
	 * Defines the available organization detail tabs based on edit mode.
	 * 
	 * @returns {Array<OrgTab>} - Array of organization detail tabs.
	 */
	const getTabList = (): Array<OrgTab> => {

		return orgTabs().slice(0, 3);
	};

	/**
	 * Filters the feature list based on selected features and returns a new list with value property.
	 * 
	 * @returns {Array<Feature>} - The filtered list of features.
	 */
	const getFeatureList = () => {
		return orgApi.state.orgState.featureList.map((feature) => {
			const hasMatchingId = feature.data?.some((d) =>
				organization.services.some((service) => service.id === d.id)
			);

			return hasMatchingId ? { ...feature, value: true } : feature;
		});
	}

	/**
	 * Renders the appropriate tab content based on the provided tab ID.
	 * 
	 * @param {string} tabId - The ID of the tab to render content for.
	 * @returns {JSX.Element} - The JSX element representing the tab content.
	 */
	const getTabPanel = (tabId: string) => {
		switch (tabId) {
			case TAB_DETAIL:
				return <DetailTab
					formikRef={formikRef}
					organization={organization}
					handleOrgDetailChange={handleOrgDetailChange}
					changeOrgStatus={changeOrgStatus}
					onValidityChange={setIsValid}
					isReadMode={isReadMode}
					toggleReadMode={setReadMode}
				/>;
			case TAB_ADMINS:
				return <AdminsTab
					organization={organization}
					handleAdminListChange={handleAdminListChange}
				/>;
			case TAB_FEATURES:
				return <FeaturesTab
					featureList={getFeatureList()}
					handleFeatureListChange={handleFeatureListChange}
				/>;
			case TAB_LOGS:
				return <LogsTab />;
			case TAB_BACK_UP:
				return <BackUpTab />;
		}
	};

	/**
	 * Gets the title for the organization detail screen based on edit/create mode.
	 * 
	 * @returns {string} - The title for the screen.
	 */
	const getTitle = (): string => {
		let title = organization.details.name? organization.details.name : t('createOrg');
		if (isEdit) {
			title = isReadMode ? organization.details.name : t('editDetails');
		}

		return title;
	}


	/**
	 * Handles the cancel button click in edit mode.
	 * Sets the read mode to true and resets the form.
	 */
	const onEditModeCancel = (): void => {
		setReadMode(true);
		formikRef.current?.resetForm();
	}

	/**
	 * Handles changes in the details tab.
	 * 
	 * @param {OrganizationDetail} orgDetails - Organization basic details.
	 */
	const handleOrgDetailChange = (orgDetails: OrganizationDetail) => {
		setIsValid(Boolean(formikRef.current?.isValid));
		const updatedOrganization = {
			...organization,
			details: {
				...orgDetails
			}
		};
		setOrganization(updatedOrganization);
	};

	/**
	 * Updates the organization admin list with new admins.
	 *
	 * @param {Array<OrganizationAdmin>} adminList - New list of organization admins.
	 */
	const handleAdminListChange = (adminList: Array<OrganizationAdmin>) => {
		const updatedAdmins = [...organization.admins, ...adminList];
		const uniqueAdmins = Array.from(new Map(updatedAdmins.map(admin => [admin.loginId, admin])).values());
		const updatedOrganization = {
			...organization,
			admins: uniqueAdmins
		};
		setOrganization(updatedOrganization);
	};

	/**
	 * Handles changes in the feature list.
	 * 
	 * @param {Array<Feature>} featureList - The updated list of features.
	 */
	const handleFeatureListChange = (featureList: Array<Feature>) => {

		const updatedOrganization = {
			...organization,
			details: {
				...organization.details,
				enabled: true
			},
			services: featureList
				.filter((feature) => feature.value)
				.flatMap((feature) => feature.data || [])
		};
		if (updatedOrganization.details.id) {
			updateOrganisation(updatedOrganization);
		} else {
			setOrganization(updatedOrganization);
		}
	};

	/**
	 * Changes the organization status.
	 * 
	 * @param {OrgStatus} status - The new status for the organization.
	 */
	const changeOrgStatus = (status: OrgStatus) => {
		const updatedOrganization = {
			...organization,
			details: {
				...organization.details,
				enabled: true,
				status: status,
			}
		};
		updateOrganisation(updatedOrganization);
	};

	/**
	 * Calls create new organization API
	 */
	const createOrganisation = () => {
		const request = {
			...organization,
			details: {
				...organization.details,
				...(formikRef.current && formikRef.current.values),
				enabled: false
			}
		}
		orgApi.createOrganization(request)
			.then((data) => {
				if (data) {
					setReadMode(true);
					setIsEdit(true);
					setOrganization(data);
				}
			});
	};

	/**
	 * Calls update organization API
	 */
	const updateOrganisation = (org: Organization, isSaveDetail?: boolean) => {

		let request = org;
		if (isSaveDetail) {
			request ={
				...org,
				details: {
					...org.details,
					...(formikRef.current && formikRef.current.values)
				}
			}
		}
		orgApi.updateOrganization(request).then((data) => {
			if (data) {
				setReadMode(true);
				setIsEdit(true);
				setOrganization(data);
			}
		});
	};

	return (
		<Box sx={styles.wrapper}>
			<Box sx={styles.innerWrapper}>
				<Typography variant='h4' sx={styles.heading}>{getTitle()}</Typography>
				<TabContext value={selectedTab}>
					<TabList variant='scrollable' onChange={handleChange} sx={styles.tabList}>
						{(!isEdit || isReadMode) &&
							getTabList().map((item: OrgTab, index: number) => (
								<Tab key={`org_tab_${index}`} value={item.id} label={item.label} sx={styles.tab} />
							))
						}
					</TabList>
					<Box sx={styles.tabListWrapper}>
						{getTabList().map((item: OrgTab, index: number) => (
							item.id === selectedTab && (
								<TabPanel key={`org_tab_panel_${index}`} value={item.id} sx={styles.tabPanel}>
									{getTabPanel(item.id)}
								</TabPanel>
							)))}
					</Box>
				</TabContext>
				{(Boolean(orgInitialValue.details.id) && !isReadMode) &&
					<Box sx={styles.multiBtnWrapper}>
						<CustomButton
							color='secondary'
							fullWidth
							title={t('cancel')}
							onClick={onEditModeCancel}
						/>
						<CustomButton
							type='submit'
							color='primary'
							fullWidth
							disabled={!isValid}
							title={t('save')}
							onClick={() => updateOrganisation(organization, true)}
						/>
					</Box>
				}
				{!organization.details.id &&
					<Box sx={styles.btnWrapper}>
						<CustomButton
							type='submit'
							title={t('save')}
							color='primary'
							disabled={!(isValid && organization.admins.length > 0)}
							endIcon={<SaveIcon />}
							onClick={createOrganisation}
						/>
					</Box>
				}
			</Box>
		</Box>
	);
};

export default OrganizationDetailScreen;
