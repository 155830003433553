import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InviteIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_6976_22552)'>
          <path d='M7.26172 9.62251L15.6674 1.2168' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M15.668 1.2168L10.701 14.9716C10.6674 15.0448 10.6136 15.1068 10.5459 15.1502C10.4781 15.1937 10.3994 15.2168 10.3189 15.2168C10.2384 15.2168 10.1596 15.1937 10.0919 15.1502C10.0242 15.1068 9.97033 15.0448 9.9368 14.9716L7.26226 9.62251L1.91316 6.94797C1.84 6.91444 1.77801 6.86061 1.73454 6.79288C1.69107 6.72515 1.66797 6.64637 1.66797 6.56589C1.66797 6.48541 1.69107 6.40662 1.73454 6.33889C1.77801 6.27116 1.84 6.21734 1.91316 6.18381L15.668 1.2168Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_6976_22552'>
            <rect width='16' height='16' fill='transparent' transform='translate(0.667969 0.216797)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default InviteIcon;