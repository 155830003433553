import { AppErrorCode, AppErrorName, HttpStatusMessages } from './exception-constants';
import { AxiosError, HttpStatusCode } from 'axios';

import AppError from './app-error';
import { t } from 'i18next';

/**
 * Error handler class for Axios errors.
 * 
 * This class takes Axios errors and translates them into more specific 
 * `AppError` instances with appropriate error codes and messages.  
 */
export default class AxiosErrorHandler {
  
  /**
   * Handles an Axios error by converting it to an AppError instance.
   * 
   * @param {AxiosError} error The Axios error to handle.
   * @returns {AppError} An AppError instance with appropriate code and message.
   */
  static handleError(error: AxiosError): AppError {
    
    let errorCode = AppErrorCode.DEFAULT_ERROR;
    let errorMessage = t('defaultErrorMsg');

    // Check for error details in the response data
    if (error.response && error.response.data && (error.response.data as any).error) { /* eslint-disable-line */
      const { status, message } = (error.response.data as any).error; /* eslint-disable-line */
      errorCode = status;
      errorMessage = message;
    } else if (error.response) {
      // Handle errors based on HTTP status code
      errorCode = error.response.status;
      switch (error.response.status) {
        case HttpStatusCode.BadRequest:
        case HttpStatusCode.Unauthorized:
        case HttpStatusCode.Forbidden:
        case HttpStatusCode.NotFound:
        case HttpStatusCode.InternalServerError:
        case HttpStatusCode.NotImplemented:
        case HttpStatusCode.BadGateway:
        case HttpStatusCode.ServiceUnavailable:
          errorMessage = HttpStatusMessages[error.response.status];
          break;
        default:
          errorMessage = HttpStatusMessages[HttpStatusCode.InternalServerError];
          break;
      }
    } else {
      // Handle Axios errors not related to response (e.g., internet connection errors)
      switch (error.name) {
        case AppErrorName.ABORT_ERROR:
          errorCode = AppErrorCode.ABORT_ERROR;
          errorMessage = t('abortError');
          break;
        case AppErrorName.TYPE_ERROR:
          errorMessage = t('typeError');
          break;
        case AppErrorName.SYNTAX_ERROR:
          errorMessage = t('syntaxError');
          break;
        case AppErrorName.NETWORK_ERROR:
          errorMessage = t('networkError');
          break;
        default:
          errorMessage = `${t('unknownError')} ${error.name}`;
          break;
      }
    }

    return new AppError(errorCode, errorMessage);
  }
}
