import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const UserIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_4844_3608)'>
          <path d='M12 13.2169C12.9682 13.2169 13.8967 12.8323 14.5813 12.1477C15.2659 11.4631 15.6505 10.5346 15.6505 9.56645C15.6505 8.5983 15.2659 7.66979 14.5813 6.9852C13.8967 6.30061 12.9682 5.91602 12 5.91602C11.0319 5.91602 10.1034 6.30061 9.4188 6.9852C8.73421 7.66979 8.34961 8.5983 8.34961 9.56645C8.34961 10.5346 8.73421 11.4631 9.4188 12.1477C10.1034 12.8323 11.0319 13.2169 12 13.2169Z' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M12.0001 1.04883C20.7612 1.04883 22.9515 3.23909 22.9515 12.0001C22.9515 20.7612 20.7612 22.9515 12.0001 22.9515C3.23909 22.9515 1.04883 20.7612 1.04883 12.0001C1.04883 3.23909 3.23909 1.04883 12.0001 1.04883Z' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M4.69922 21.7953V21.7344C4.69922 20.4436 5.21202 19.2056 6.1248 18.2928C7.03759 17.38 8.2756 16.8672 9.56647 16.8672H14.4337C15.7246 16.8672 16.9626 17.38 17.8754 18.2928C18.7882 19.2056 19.301 20.4436 19.301 21.7344V21.7953' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_4844_3608'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default UserIcon;