import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SearchIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_6255_9700)'>
          <circle cx='6.42635' cy='7.35547' r='5.41408' transform='rotate(-45 6.42635 7.35547)' stroke='currentColor' strokeWidth='2' />
          <path d='M14.2947 16.6425C14.6852 17.033 15.3184 17.033 15.7089 16.6425C16.0994 16.252 16.0994 15.6188 15.7089 15.2283L14.2947 16.6425ZM9.57024 11.918L14.2947 16.6425L15.7089 15.2283L10.9845 10.5038L9.57024 11.918Z' fill='currentColor' />
        </g>
        <defs>
          <clipPath id='clip0_6255_9700'>
            <rect width='16' height='16' fill='white' transform='translate(0 0.933594)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default SearchIcon;