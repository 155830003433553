import { Box, Stack, Typography } from '@mui/material';

import React from 'react';
import useStyles from './styles';

interface Props {
  heading: string;
  subHeading: string;
}

/**
 * Reusable component for displaying a logo, heading, and sub-heading.
 *
 * This component takes props for the heading and sub-heading text,
 * and renders them along with a logo image within a styled Stack container.
 *
 * @props {Props} - Component properties defining the header and sub-heading text.
 *   - `heading` (string): The main heading text.
 *   - `subHeading` (string): The sub-heading text displayed below the heading.
 *
 * @returns {JSX.Element} - The rendered logo header component.
 */
const LogoHeader: React.FC<Props> = (props: Props) => {

  const styles = useStyles();

  return (
    <Stack sx={styles.header}>
      <Box component='img' alt='logo' sx={styles.logo} />
      <Typography variant='h1'>{props.heading}</Typography>
      <Typography variant='p1'>{props.subHeading}</Typography>
    </Stack>
  );
};

export default LogoHeader;
