/**
 * useStyles custom hook generates MUI theme dependent styles for the MasterDetailLayout component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const styles = {
    masterLayout: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      height: 'inherit'
    },
    leftPane: {
      flex: 0.3,
      height: 'inherit'
    },
    rightPane: {
      flex: 0.7,
      height: 'inherit'
    }
  };

  return styles;
};

export default useStyles;