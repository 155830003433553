import { Box, Grid } from '@mui/material';

import React from 'react';
import useStyles from './styles';

interface MinimalProps {
  children: React.ReactNode;
}

/**
 * Minimal component renders a basic layout for application views accessed before login.
 * 
 * Props:
 *  - children (React.ReactNode): Content to be displayed within the main application area.
 */
const Minimal: React.FC<MinimalProps> = (props: MinimalProps) => {

  const { children } = props;
  const styles = useStyles();

  return (
    <Box sx={styles.root}>
      <Box component='main' sx={styles.content}>
        {children}
      </Box>
    </Box>
  );
};

export default Minimal;
