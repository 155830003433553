import { Box, Fab, List, useMediaQuery, useTheme } from '@mui/material';
import { INIT_ORGANIZATION, getOrgStatusMenu } from '../../../utils/constants';
import React, { useEffect, useState } from 'react';

import APP_NAV from '../../../routes/app-nav';
import AddIcon from '../../../components/CustomIcons/AddIcon';
import CircularProgressBar from '../../../components/CircularProgressBar';
import { FETCH_ORG_LIST } from '../../../data/organization/action-types';
import InlineAlert from '../../../components/InlineAlert';
import MenuData from '../../../types/ui/menu-data';
import OrgListSearchFilterHeader from './OrgListSearchFilterHeader';
import Organization from '../../../types/organization';
import OrganizationListItem from './OrganizationListItem';
import Util from '../../../utils/util';
import { useNavigate } from 'react-router-dom';
import { useOrganizationApi } from '../../../data/organization/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Renders the Organization List screen, allowing users to view, search, and filter organizations.
 *
 * @returns {JSX.Element} The Organization List screen component.
 */
const OrganizationListScreen: React.FC = () => {

  const styles = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState<string>('');
  const [orgStatusFilter, setOrgStatusFilter] = useState<Array<MenuData>>(getOrgStatusMenu());
  const orgApi = useOrganizationApi();
  const apiStatus = orgApi.state.orgState.apiStatus;

  /**
   * Fetches initial data (organization, license, and feature lists) on component mount.
   */
  useEffect(() => {
    fetchInitialApis();
  }, []);

  /**
   * Fetches organization, license, and feature lists if they are empty.
   */
  const fetchInitialApis = () => {
    if (Util.isArrayEmpty(orgApi.state.orgState.orgList)) {
      orgApi.fetchOrganizationList();
    }
    if (Util.isArrayEmpty(orgApi.state.orgState.licenseList)) {
      orgApi.fetchLicenceList();
    }
    if (Util.isArrayEmpty(orgApi.state.orgState.featureList)) {
      orgApi.fetchFeatureList();
    }
  }

  /**
   * Searches and filters organizations based on search key and selected statuses.
   *
   * @returns {Organization[]} An array of filtered organizations.
   */
  const searchAndFilterSites = () => {
    const checkedStatuses = orgStatusFilter
      .filter((menu: MenuData) => menu.isChecked)
      .map((menu: MenuData) => menu.id);

    return orgApi.state.orgState.orgList.filter((org: Organization) =>
      org.details.name.toLowerCase().includes(searchKey.toLowerCase()) &&
      checkedStatuses.includes(org.details.status)
    );
  };

  const filteredOrgList = searchAndFilterSites();

  /**
   * Navigates to the organization detail screen for the selected organization.
   *
   * @param {Organization} item The selected organization.
   */
  const onItemSelected = (item: Organization) => {
    const navPath = isLargeScreen ? `${APP_NAV.ORGANIZATION}${APP_NAV.ORG_DETAIL_REL_PATH}`
      : APP_NAV.ORG_DETAIL_REL_PATH.replace('/', '');
    navigate(navPath, {
      state: {
        ...item
      }
    });
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.innerWrapper}>
        <InlineAlert message={Util.getApiError([FETCH_ORG_LIST], apiStatus)} />
        <OrgListSearchFilterHeader
          orgStatusFilter={orgStatusFilter}
          onSearchChange={setSearchKey}
          onOrgStatusFilterChange={setOrgStatusFilter}
        />
        {!Util.isArrayEmpty(filteredOrgList) &&
          <List sx={styles.list}>
            {filteredOrgList.map((item: Organization, index: number) =>
              <OrganizationListItem
                key={`org_list_item_${index}`}
                organization={item.details}
                onItemSelected={() => onItemSelected(item)} />
            )}
          </List>}
        <Fab color='primary' aria-label='add' sx={styles.fab} onClick={() => onItemSelected(INIT_ORGANIZATION)}>
          <AddIcon />
        </Fab>
      </Box>
      <CircularProgressBar show={Util.isApiLoading(FETCH_ORG_LIST, apiStatus)} />
    </Box>
  );
};

export default OrganizationListScreen;