import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the Verification component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    innerWrapper: {
      maxWidth: Dimens.loginLayoutWidth,
      p: Dimens.spacing.card,
      borderRadius: Dimens.radius.md,
      bgcolor: theme.palette.background.paper,
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        borderRadius: 0,
        minWidth: '100%',
        maxWidth: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }
    },
    spacer: {
      height: Dimens.spacing.paper
    }
  };

  return styles;
};

export default useStyles;