import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EyeIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M7.4082 8.49982C7.4082 8.9222 7.57599 9.32728 7.87466 9.62595C8.17333 9.92462 8.57841 10.0924 9.0008 10.0924C9.42318 10.0924 9.82826 9.92462 10.1269 9.62595C10.4256 9.32728 10.5934 8.9222 10.5934 8.49982C10.5934 8.07744 10.4256 7.67236 10.1269 7.37369C9.82826 7.07502 9.42318 6.90723 9.0008 6.90723C8.57841 6.90723 8.17333 7.07502 7.87466 7.37369C7.57599 7.67236 7.4082 8.07744 7.4082 8.49982Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M16.1673 8.50043C14.2562 11.6856 11.8673 13.2782 9.00065 13.2782C6.13398 13.2782 3.7451 11.6856 1.83398 8.50043C3.7451 5.31525 6.13398 3.72266 9.00065 3.72266C11.8673 3.72266 14.2562 5.31525 16.1673 8.50043Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default EyeIcon;