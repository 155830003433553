import Dimens from '../../theme/dimens';
import { Props } from '.';
import { useTheme } from '@mui/material';

const useStyles = (props: Props) => {

  const { base, lg, xl, container } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    dialog: {
      '& .MuiDialog-paper': {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        m: 0,
        borderRadius: Dimens.radius.md,
        maxWidth: '100%',
        width: Dimens.alertDialogLargeWidth.lg,
        p: container,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
          width: Dimens.alertDialogLargeWidth.md
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          height: '100%',
          minHeight: '100%',
          borderRadius: 0,
          justifyContent: 'center',
          p: xl
        }
      },
      '& .MuiDialogContent-root': {
        p: 0,
        overflow: 'hidden',
        'h1': {
          display: props.footerCaption ? 'inherit' : 'none'
        },
        'h2': {
          display: props.footerCaption ? 'none' : 'inherit'
        },
        [theme.breakpoints.down('sm')]: {
          flex: 'none'
        }
      },
      '& .MuiDialogActions-root': {
        p: 0,
        alignItems: 'center',
        justifyContent: 'center',
        'button': {
          minWidth: Dimens.buttonMinWidth
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          'button': {
            width: '100%'
          }
        },
        '& .MuiButton-colorSecondary': {
          display: props.secondaryLabel ? 'flex' : 'none',
          mr: Dimens.spacing.xs,
          [theme.breakpoints.down('sm')]: {
            mb: Dimens.spacing.xs
          }
        },
        '& .MuiButton-colorPrimary': {
          display: props.primaryLabel ? 'flex' : 'none',
          ml: 0,
          [theme.breakpoints.down('sm')]: {
            margin: 0
          }
        }
      }
    },
    header: {
      alignItems: 'end',
      position: 'absolute',
      right: 0,
      top: 0,
      p: lg,
      '& button': {
        p: 0
      },
      [theme.breakpoints.down('sm')]: {
        pb: base
      }
    },
    closeIcon: {
      p: 0,
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.sm
    },
    headerImage: {
      pb: lg
    },
    message: {
      pb: lg,
    },
    footer: {
      textAlign: 'center',
      pt: lg,
      display: props.footerCaption ? 'unset' : 'none'
    },
    footerTxt: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      display: props.footerAction ? 'unset' : 'none'
    },
    title: {
      pb: lg,
      fontWeight: Dimens.fontWeight.bold
    }
  };

  return styles;
};

export default useStyles;
