import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * Creates MUI theme-based styles for the `AlertDialog` component.
 *
 * @returns {Object} - An object containing styled classes for the `AlertDialog` component.
 */
const useStyles = () => {

  const { xs, base, lg, xl, container } = Dimens.spacing;
  const theme = useTheme();
  const styles = {
    dialog: {
      '& .MuiDialog-paper': {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        m: 0,
        borderRadius: Dimens.radius.md
      },
      '& .MuiDialogTitle-root': {
        p: 0
      },
      '& .MuiDialogContent-root': {
        p: 0
      },
      '& .MuiDialogActions-root': {
        p: 0
      }
    },
    dialogSmall: {
      '& .MuiDialog-paper': {
        maxWidth: Dimens.alertDialogSmallWidth.lg,
        p: `${xl} ${xl} ${lg} ${xl}`,
        [theme.breakpoints.down('sm')]: {
          maxWidth: Dimens.alertDialogSmallWidth.sm
        }
      }
    },
    dialogLarge: {
      '& .MuiDialog-paper': {
        maxWidth: Dimens.alertDialogLargeWidth.lg,
        p: `${container} ${container} ${xl} ${container}`,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
          maxWidth: Dimens.alertDialogLargeWidth.md,
          p: lg
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          height: '100%',
          p: lg
        }
      },
      '& .MuiDialogContent-root': {
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }
      },
      '& .MuiDialogActions-root': {
        '& .MuiButton-colorSecondary': {
          [theme.breakpoints.down('sm')]: {
            display: 'none'
          }
        },
        '& .MuiButton-colorPrimary': {
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flex: 1,
            margin: 0
          }
        }
      }
    },
    header: {
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'space-between',
      pb: lg,
      '& button': {
        p: 0
      },
      [theme.breakpoints.down('sm')]: {
        pb: base
      }
    },
    noPad: {
      p: '0 !important'
    },
    closeIcon: {
      p: 0,
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.sm
    },
    headerImage: {
      pb: xl,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    messageLarge: {
      py: lg,
    },
    messageSmall: {
      p: `${xs} 0 ${lg} 0`,
    },
    actionSmall: {
      'button': {
        flex: 1
      }
    },
    actionLarge: {
      alignItems: 'center',
      justifyContent: 'center',
      'button': {
        minWidth: Dimens.buttonMinWidth
      }
    }
  };

  return styles;
};

export default useStyles;
