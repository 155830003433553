import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PencilIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_5612_11352)'>
          <path d='M1.66406 23.25H7.27455L22.0021 8.52246C22.3705 8.15407 22.6627 7.71673 22.8621 7.2354C23.0615 6.75408 23.1641 6.2382 23.1641 5.71722C23.1641 5.19623 23.0615 4.68035 22.8621 4.19903C22.6627 3.7177 22.3705 3.28036 22.0021 2.91197C21.6337 2.54358 21.1964 2.25136 20.715 2.05199C20.2337 1.85262 19.7178 1.75 19.1968 1.75C18.6759 1.75 18.16 1.85262 17.6787 2.05199C17.1973 2.25136 16.76 2.54358 16.3916 2.91197L1.66406 17.6395V23.25Z' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M14.989 4.31445L20.5995 9.92494' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_5612_11352'>
            <rect width='24' height='24' fill='transparent' transform='translate(0.414062 0.5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default PencilIcon;