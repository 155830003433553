import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CaretDownIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M13.1414 5.07227C13.8717 5.07227 14.2531 5.91743 13.8125 6.46344L13.7474 6.53544L8.60443 11.6784C8.45683 11.826 8.26045 11.9146 8.05214 11.9277C7.84383 11.9408 7.63789 11.8775 7.47297 11.7495L7.3924 11.6784L2.24943 6.53544L2.17829 6.45487L2.132 6.38886L2.08572 6.30658L2.07114 6.27572L2.048 6.21829L2.02057 6.12572L2.012 6.08029L2.00343 6.02886L2 5.98V5.87885L2.00429 5.82914L2.012 5.77771L2.02057 5.73314L2.048 5.64056L2.07114 5.58313L2.13115 5.46999L2.18686 5.39284L2.24943 5.32341L2.33001 5.25227L2.39601 5.20598L2.4783 5.1597L2.50915 5.14512L2.56658 5.12198L2.65916 5.09455L2.70459 5.08598L2.75602 5.07741L2.80487 5.07398L13.1414 5.07227Z' fill='currentColor' />
      </svg>
    </SvgIcon>
  );
}

export default CaretDownIcon;