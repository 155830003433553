import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const WordIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M1.48264 8C1.48264 8.91925 1.6637 9.82951 2.01548 10.6788C2.36726 11.5281 2.88288 12.2997 3.53289 12.9497C4.1829 13.5998 4.95457 14.1154 5.80385 14.4672C6.65313 14.8189 7.56338 15 8.48264 15C9.40189 15 10.3121 14.8189 11.1614 14.4672C12.0107 14.1154 12.7824 13.5998 13.4324 12.9497C14.0824 12.2997 14.598 11.5281 14.9498 10.6788C15.3016 9.82951 15.4826 8.91925 15.4826 8C15.4826 6.14348 14.7451 4.36301 13.4324 3.05025C12.1196 1.7375 10.3392 1 8.48264 1C6.62612 1 4.84564 1.7375 3.53289 3.05025C2.22013 4.36301 1.48264 6.14348 1.48264 8Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M1.9493 5.66666H15.016' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M1.9493 10.3333H15.016' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8.09373 1C6.78344 3.0997 6.08878 5.52501 6.08878 8C6.08878 10.475 6.78344 12.9003 8.09373 15' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8.87152 1C10.1818 3.0997 10.8765 5.52501 10.8765 8C10.8765 10.475 10.1818 12.9003 8.87152 15' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default WordIcon;