import {
  API_FAILURE,
  ApiFailureAction,
  INIT_API,
  InitApiAction,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  SignInSuccessAction,
  SignOutSuccessAction
} from './action-types';

import BearerToken from '../../types/bearer-token';

/**
 * Creates an action object to indicate the initiation of an API call.
 *
 * This function returns an action object with the type `INIT_API`.
 *
 * @returns {InitApiAction} - The action object for API call initiation.
 */
export const initApi = (): InitApiAction => ({
  type: INIT_API
});

/**
 * Creates an action object to indicate successful sign-in with a bearer token.
 *
 * This function takes a `BearerToken` object as input and returns an action object
 * with the type `SIGN_IN_SUCCESS` and the bearer token in the payload.
 *
 * @param {BearerToken} bearerToken - The bearer token received from the successful sign-in response.
 * @returns {SignInSuccessAction} - The action object for successful sign-in.
 */
export const signInSuccess = (bearerToken: BearerToken): SignInSuccessAction => ({
  type: SIGN_IN_SUCCESS,
  payload: bearerToken
});

/**
 * Creates an action object to indicate successful sign-out.
 *
 * This function returns an action object with the type `SIGN_OUT_SUCCESS` and
 * a payload of `true`.
 *
 * @returns {SignOutSuccessAction} - The action object for successful sign-out.
 */
export const signOutSuccess = (): SignOutSuccessAction => ({
  type: SIGN_OUT_SUCCESS,
  payload: true
});

/**
 * Creates an action object to indicate an API call failure with an error message.
 *
 * This function takes an error message as input and returns an action object
 * with the type `API_FAILURE` and the error message in the payload.
 *
 * @param {string} error - The error message encountered during the API call.
 * @returns {ApiFailureAction} - The action object for API call failure.
 */
export const apiFailure = (error: string): ApiFailureAction => ({
  type: API_FAILURE,
  payload: error
});