import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LogoutIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='17' viewBox='0 0 17 17' fill='none' stroke='currentColor' xmlns='http://www.w3.org/2000/svg'>
        <path d='M11.3203 12.8068L15.627 8.50007L11.3203 4.19336' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M15.6426 8.5L6.15502 8.5' strokeWidth='2' strokeLinecap='round' />
        <path d='M5.40033 2.12256H5.28906C3.07992 2.12256 1.28906 3.91342 1.28906 6.12256V10.8781C1.28906 13.0872 3.07992 14.8781 5.28906 14.8781H5.40033' strokeWidth='2' strokeLinecap='round' />
      </svg>
    </SvgIcon>
  );
}

export default LogoutIcon;