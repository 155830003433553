import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';

import Feature from '../../../../types/feature';
import FeatureCard from './FeatureCard';
import { Masonry } from '@mui/lab';
import { useContainerSize } from '../../../../hooks/use-container-size';
import useStyles from './styles';

interface Props {
  featureList: Array<Feature>;
  handleFeatureListChange: (featureList: Array<Feature>) => void;
}

/**
 * Renders the "Features" tab displaying a grid of feature cards.
 *
 * @returns {JSX.Element} JSX element representing the FeaturesTab component.
 */
const FeaturesTab: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [width] = useContainerSize(containerRef);
  const numOfColumns = Math.floor(width / 200);
  const [featureList, setFeatureList] = useState<Array<Feature>>(props.featureList);

  /**
   * Handles checkbox change for a feature.
   * This function updates the feature list based on the checked state of the checkbox.
   * It updates the local state and calls the prop function to inform parent component about the change.
   * 
   * @param {Feature} feature - The feature object representing the clicked checkbox.
   * @param {boolean} checked - The checked state of the checkbox (true/false).
   */
  const onChange = (feature: Feature, checked: boolean) => {
    const updatedFeatureList = featureList.map((f) =>
      f.label === feature.label ? { ...f, value: checked } : f
    );
    setFeatureList(updatedFeatureList);
    props.handleFeatureListChange(updatedFeatureList);
  }

  return (
    <Box sx={styles.innerWrapper}>
      <Grid sx={styles.contentWrapper} ref={containerRef}>
        <Masonry columns={numOfColumns === 0 ? 1 : numOfColumns} spacing={isMobile ? 1 : 2} sx={styles.content} sequential>
          {featureList.map((feature, index) => (
            <FeatureCard key={`org-feature-${index}`} feature={feature} onChange={checked => onChange(feature, checked)} />
          ))}
        </Masonry>
      </Grid>
    </Box>
  );
};

export default FeaturesTab;