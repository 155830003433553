import { apiFailure, featureListSuccess, fetchOrgList, initAdminInvite, initFeatureListFetch, initLicenceListFetch, initOrgCreate, initOrgDetailFetch, initOrgUpdate, licenceListSuccess, orgListSuccess, resetApiState } from './actions';
import { doGet, doPatch, doPost } from '../../service';

import APIConfig from '../../service/api-config';
import FeatureListResponse from '../../types/feature-list-response';
import InviteAdminResponse from '../../types/invite-admin-response';
import LicenceListResponse from '../../types/licence-list-response';
import Organization from '../../types/organization';
import OrganizationListResponse from '../../types/org-list-response';
import OrganizationResponse from '../../types/organization-response';
import React from 'react';
import { Store } from '../../store/store';
import Util from '../../utils/util';
import { t } from 'i18next';
import { useCallback } from 'react';

/**
 * Custom Hook for Organization API Calls
 *
 * This hook provides functions for fetching and managing organization data using API calls.
 * It utilizes the `organizationReducer` and initial state (`orgInitialState`) for state management.
 *
 * @returns {Object} - Returns an object containing the exposed functions for utilizing organization API functionalities.
 */
export function useOrganizationApi() {

	const { state, dispatch } = React.useContext(Store);

	/**
	 * Fetches the organization list from the API endpoint defined in `APIConfig.organization`.
	 * Dispatches actions to manage the fetch process and handle success/error scenarios.
	 */
	const fetchOrganizationList = useCallback(async () => {
		dispatch(fetchOrgList());
		try {
			const response: OrganizationListResponse = await doGet(APIConfig.organization);
			const orgList: Array<Organization> = [];
			if (!Util.isArrayEmpty(response.data)) {
				response.data?.forEach((item) => {
					const organization: Organization = {
						details: item,
						admins: [],
						services: []
					};
					orgList.push(organization);
				});
			}
			dispatch(orgListSuccess(orgList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Fetches the licence list from the API endpoint defined in `APIConfig.licence`.
	 * Dispatches actions to manage the fetch process and handle success/error scenarios.
	 */
	const fetchLicenceList = useCallback(async () => {
		dispatch(initLicenceListFetch());
		try {
			const response: LicenceListResponse = await doGet(APIConfig.licence);
			if (!Util.isArrayEmpty(response.data)) {
				response.data.forEach(item => {
					item.value = t('upToMaxSize', { maxSize: item.maxSize });
				});
			}
			dispatch(licenceListSuccess(response.data || []));
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Fetches the feature list from the API endpoint defined in `APIConfig.features`.
	 * Dispatches actions to manage the fetch process and handle success/error scenarios.
	 */
	const fetchFeatureList = useCallback(async () => {
		dispatch(initFeatureListFetch());
		try {
			const response: FeatureListResponse = await doGet(APIConfig.features);
			const featureList = response.data || [];

			// Iterate through each feature
			featureList.forEach(feature => {
				// Check if any child item has value set to true
				if (feature.data && feature.data.some(child => child.value)) {
					// Set the parent value to true if any child has value true
					feature.value = true;
					feature.disabled = true;
				}
			});
			dispatch(featureListSuccess(featureList));
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Fetches the details of a specific organization by its ID.
	 * Dispatches actions to manage the fetch process and handle success/error scenarios.
	 *
	 * @param {string} orgId - The ID of the organization to fetch details for.
	 *
	 * @returns {Promise<Organization>} - The fetched organization data.
	 */
	const fetchOrganizationDetail = useCallback(async (orgId: string) => {
		dispatch(initOrgDetailFetch());
		try {
			const response: OrganizationResponse = await doGet(`${APIConfig.organization}/${orgId}`);
			response.data.isFetched = true;
			const orgList = [...state.orgState.orgList].map(org =>
				org.details.id === orgId ? response.data : org
			);
			dispatch(orgListSuccess(orgList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.orgState.orgList]);

	/**
	 * Creates a new organization by sending a POST request to the API.
	 * Dispatches actions to initiate the creation process and manage success/error scenarios.
	 *
	 * @param {Organization} organization - The organization object containing the details to be created.
	 *
	 * @returns {Promise<Organization>} - The newly created organization data.
	 */
	const createOrganization = useCallback(async (organization: Organization) => {
		dispatch(initOrgCreate());
		try {
			const response: OrganizationResponse = await doPost(APIConfig.organization, organization);
			response.data.isFetched = true;
			const orgList = [...state.orgState.orgList, response.data];
			dispatch(orgListSuccess(orgList));
			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.orgState.orgList]);

	/**
	 * Updates an existing organization by sending a PATCH request to the API.
	 * Dispatches actions to initiate the update process and manage success/error scenarios.
	 *
	 * @param {Organization} organization - The organization object containing the updated details.
	 *
	 * @returns {Promise<Organization>} - The updated organization data.
	 */
	const updateOrganization = useCallback(async (org: Organization) => {
			dispatch(initOrgUpdate());
			try {
				const request = {
					...org,
					details: {
						...org.details,
						id: undefined
					}
				};
				const response: OrganizationResponse = await doPatch(`${APIConfig.organization}/${org.details.id}`, request);
				const updatedOrganization = {
					...response.data,
					isFetched: true,
				};
				const updatedOrgList = state.orgState.orgList.map(existingOrg =>
					existingOrg.details.id === org.details.id
						? { ...existingOrg, ...updatedOrganization }
						: existingOrg
				);
				dispatch(orgListSuccess(updatedOrgList));

				return response.data;
			} catch (error: any) {/* eslint-disable-line */
				dispatchFailureAction(error);
			}
		}, [state.orgState.orgList]);

	/**
	 * Invites an administrator to join the organization by sending a patch request.
	 *
	 * @param {string} invite - The invite URL or identifier for the admin to be invited.
	 * @returns {Promise<any>} - A promise that resolves with the response data if the request is successful.
	 *                            If the request fails, it does not resolve; the error is handled internally.
	 */
	const InviteOrganizationAdmin = useCallback(async (invite: string) => {
		dispatch(initAdminInvite());
		try {
			const response: InviteAdminResponse = await doPatch(`${invite}`);
			dispatch(resetApiState())
			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Dispatches an `apiFailure` action with an error message for failure scenarios.
	 *
	 * @param {Error} error - Optional error object encountered during api processes.
	 */
	const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
		const message: string = (error && error.message) ? error.message : t('defaultErrorMsg');
		dispatch(apiFailure(message));
	}

	/**
	 * Resets the error state in the Redux store.
	 */
	const resetError = () => {
		if (state.orgState.apiStatus?.error) {
			dispatch(resetApiState());
		}
	}

	/**
	 * Resets the error state in the Redux store.
	 */
	const resetLoading = () => {
		if (state.orgState.apiStatus?.isLoading) {
			dispatch(resetApiState());
		}
	}

	return {
		fetchOrganizationList,
		fetchLicenceList,
		fetchFeatureList,
		fetchOrganizationDetail,
		createOrganization,
		updateOrganization,
		InviteOrganizationAdmin,
		resetError,
		state
	};
}