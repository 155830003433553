import Colors from '../../../../theme/colors';
import Dimens from '../../../../theme/dimens';
import { OrgStatus } from '../../../../utils/constants';
import ThemeUtil from '../../../../theme/theme-util';
import { useTheme } from '@mui/material';

type UseStylesProps = {
  status: string;
};

/**
 * useStyles custom hook generates MUI theme dependent styles for the OrganizationListItem component.
 * @param {UseStylesProps} props - Props passed from the component to determine dynamic styles.
 * @returns An object containing the styled classes.
 */
const useStyles = (props:UseStylesProps) => {

  const { xs, md, lg, section } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    listItem: {
      p: `${lg} ${section}`,
      borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.divider}`,
      [theme.breakpoints.down('sm')]: {
        p: lg
      }
    },
    avatar: {
      width: Dimens.avatarSize,
      height: Dimens.avatarSize,
      color: theme.palette.primary.contrastText,
      bgcolor: ThemeUtil.isLightTheme(theme) ? theme.palette.text.primary : Colors.palette.secondary[300]
    },
    avatarWrapper: {
      minWidth: 0
    },
    textEllipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    textWrapper: {
      p: `0 ${lg}`,
      m: 0,
      display: 'inline-grid',
      whiteSpace: 'nowrap'
    },
    primaryText: {
      fontWeight: Dimens.fontWeight.bold,
      color: theme.palette.text.primary
    },
    secondaryText: {
      color: theme.palette.text.secondary
    },
    status: {
      p: `${xs} ${md}`,
      borderRadius: Dimens.radius.md
    },
    statusDynamic: {
      ...(props.status === OrgStatus.Active && {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
      }),
      ...(props.status === OrgStatus.Draft && {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.text.secondary,
      }),
      ...(props.status === OrgStatus.Inactive && {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.error.main,
      }),
    },
  };

  return styles;
};

export default useStyles;