import DarkBrandLogo from '../../../../assets/brand_logo_dark.svg';
import Dimens from '../../../../theme/dimens';
import LightBrandLogo from '../../../../assets/brand_logo_light.svg';
import ThemeUtil from '../../../../theme/theme-util';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the TopBar component.
 * 
 * Styles leverage theme properties like palette and spacing for a consistent look.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    appBar: {
      bgcolor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      backgroundImage: 'none',
      boxShadow: 'none'
    },
    logo: {
      height: Dimens.icon.lg,
      content: `url(${ThemeUtil.isLightTheme(theme) ? LightBrandLogo : DarkBrandLogo})`,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    toolBarMenu: {
      p: 0,
      color: theme.palette.text.primary,
      [theme.breakpoints.down('sm')]: {
        '& .MuiListItemIcon-root': {
          minWidth: 0
        },
        '& .MuiListItemText-root': {
          display: 'none'
        }
      }
    },
    destructive: {
      color: theme.palette.error.main
    },
    menuPaperProps: {
      borderRadius: Dimens.radius.md,
      backgroundImage: 'none',
      border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`  
    },
    menu: {
      padding: 0
    },
    menuItem: {
      p: `${Dimens.spacing.md} ${Dimens.spacing.lg}`,
      minWidth: Dimens.menuItemMinWidth,
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.text.primary,
			'&:hover': {
				backgroundColor: theme.palette.background.default
			},
			'&.Mui-selected': {
				backgroundColor: theme.palette.background.paper,
				'&:hover': {
					backgroundColor: theme.palette.background.default
				}
			}
		},
    menuDestructive:{
      '&:hover': {
        backgroundColor: theme.palette.error.light
      }
    }, 
    iconWrapper: {
      '&.MuiListItemIcon-root': {
        minWidth: ThemeUtil.pxToRem(30)
      }
    },
    icon: {
      fontSize: Dimens.icon.sm,
      color: theme.palette.error.main
    },
    label: {
      p: Dimens.spacing.lg,
      fontWeight: Dimens.fontWeight.medium
    },
    divider: {
			my: '0 !important',
      borderBottomWidth: Dimens.menuBorderWidth,
      borderColor: theme.palette.background.default
		},
    checkIcon: {
			fontSize: `${Dimens.icon.sm} !important`
		},
  };

  return styles;
};

export default useStyles;
