import Feature from '../../types/feature';
import Licence from '../../types/licence';
import Organization from '../../types/organization';

export const CREATE_ORG = 'CREATE_ORG';
export const UPDATE_ORG = 'UPDATE_ORG';
export const INVITE_ADMIN = 'INVITE_ADMIN';
export const FETCH_ORG_LIST = 'FETCH_ORG_LIST';
export const ORG_LIST_SUCCESS = 'ORG_LIST_SUCCESS';
export const FETCH_LICENCE_LIST = 'FETCH_LICENCE_LIST';
export const LICENCE_LIST_SUCCESS = 'LICENCE_LIST_SUCCESS';
export const FETCH_FEATURE_LIST = 'FETCH_FEATURE_LIST';
export const FEATURE_LIST_SUCCESS = 'FEATURE_LIST_SUCCESS';
export const FETCH_ORG_DETAIL = 'FETCH_ORG_DETAIL';
export const API_FAILURE = 'API_FAILURE';
export const RESET_API_STATE = 'RESET_API_STATE';

export interface OrgListFetchAction {
  type: typeof FETCH_ORG_LIST;
}

export interface OrgListSuccessAction {
  type: typeof ORG_LIST_SUCCESS;
  payload: Array<Organization>;
}

export interface LicenceListFetchAction {
  type: typeof FETCH_LICENCE_LIST;
}

export interface LicenceListSuccessAction {
  type: typeof LICENCE_LIST_SUCCESS;
  payload: Array<Licence>;
}

export interface FeatureListFetchAction {
  type: typeof FETCH_FEATURE_LIST;
}

export interface FeatureListSuccessAction {
  type: typeof FEATURE_LIST_SUCCESS;
  payload: Array<Feature>;
}

export interface OrgCreateAction {
  type: typeof CREATE_ORG;
}

export interface OrgUpdateAction {
  type: typeof UPDATE_ORG;
}

export interface InviteAdminAction {
  type: typeof INVITE_ADMIN;
}

export interface OrgDetailFetchAction {
  type: typeof FETCH_ORG_DETAIL;
}

export interface ApiFailureAction {
  type: typeof API_FAILURE;
  payload: string;
}

export interface ResetApiStateAction {
  type: typeof RESET_API_STATE
}

export type OrgActionTypes = OrgListFetchAction | OrgListSuccessAction | LicenceListFetchAction
  | FeatureListFetchAction | FeatureListSuccessAction | LicenceListSuccessAction | OrgDetailFetchAction
  | ApiFailureAction | OrgCreateAction | OrgUpdateAction | InviteAdminAction | ResetApiStateAction;