import {
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Dimens from '../../theme/dimens';
import { FieldProps } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import useStyles from './styles';

type Props = {
  label?: string;
  subLabel?: string;
  placeholder?: string;
  readOnly?: boolean;
} & FieldProps;

/**
 * Custom DatePicker component with additional styling and error handling.
 *
 * @param {Props} props - Component props containing label, sublabel, placeholder, and Formik field props.
 * 
 * @returns {JSX.Element} JSX element representing the CustomDatePicker component.
 */
const CustomDatePicker: React.FC<Props> = (props: Props) => {

  const {
    field: { name, onBlur, onChange, value },
    form: { errors, touched, setFieldTouched, setFieldValue },
    label,
    subLabel,
    placeholder,
    readOnly,
    ...rest
  } = props;
  const styles = useStyles();
  const hasError = Boolean(errors[name] && touched[name]);

  /**
   * Handles changes to the date picker value.
   *
   * @param {Dayjs | null} value - The selected date value.
   */
  const handleChange = (value: Dayjs | null) => {
    setFieldValue(name, value);
    handleBlur();
  }

  /**
   * Handles blur events on the date picker input.
   */
  const handleBlur = () => {
    setFieldTouched(name);
    onBlur(name);
  }

  return (
    <Stack sx={styles.wrapper}>
      {label &&
        <Stack sx={styles.header}>
          <Typography variant='p1' fontWeight={Dimens.fontWeight.semiBold}>{label}</Typography>
          {subLabel && (
            <Typography variant='p1' sx={styles.subLabel}>
              {subLabel}
            </Typography>
          )}
        </Stack>
      }
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']} sx={styles.datePicker}>
          <DatePicker
            value={value ? dayjs(value) : null} 
            label={placeholder}
            disabled={readOnly}
            onChange={(value) => handleChange(value)}
            minDate={dayjs()}
            slotProps={{
              textField: {
                InputProps: {
                  readOnly: readOnly 
                }
              }
            }}
            {...rest}
          />
        </DemoContainer>
      </LocalizationProvider>
      {hasError && <FormHelperText sx={styles.fieldError}>{errors[name]?.toString()}</FormHelperText>}
    </Stack>
  );
};

export default CustomDatePicker;
