/**
 * useStyles custom hook generates MUI theme dependent styles for the Minimum component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const styles = {
    root: {
      height: 'inherit',
    },
    content: {
      height: 'inherit',
      display: 'flex',
      flexDirection: 'column'
    }
  }

  return styles;
}

export default useStyles;
