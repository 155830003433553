/**
 * This file defines various constants related to error handling in the application.
 */

import { HttpStatusCode } from 'axios';
import { t } from 'i18next';

/*----------------------------------------------------------------------------------*/

/**
 * Http Status messages.
 */
export const HttpStatusMessages = {
  [HttpStatusCode.BadRequest]: t('badRequest'),
  [HttpStatusCode.Unauthorized]: t('unauthorized'),
  [HttpStatusCode.Forbidden]: t('forbidden'),
  [HttpStatusCode.NotFound]: t('notFound'),
  [HttpStatusCode.InternalServerError]: t('internalServerError'),
  [HttpStatusCode.NotImplemented]: t('notImplemented'),
  [HttpStatusCode.BadGateway]: t('internalServerError'),
  [HttpStatusCode.ServiceUnavailable]: t('serviceUnavailable')
};

/**
 * Enum containing names of common Axios error types for reference.
 */
export enum AppErrorName {
  ABORT_ERROR = 'AbortError',
  TYPE_ERROR = 'TypeError',
  SYNTAX_ERROR = 'SyntaxError',
  NETWORK_ERROR = 'NetworkError'
}

/**
 * Enum containing custom application error codes for reference.
 * These codes can be used to identify specific error types within the application.
 */
export enum AppErrorCode {
  ABORT_ERROR = 0,
  DEFAULT_ERROR = 600
}