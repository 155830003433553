import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the DetailTab component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const { xs, sm, base, lg, xl, section } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    wrapper: {
      display: 'flex',
      flexGrow: 1,
      height: 'inherit',
      overflow: 'hidden'
    },
    innerWrapper: {
      display: 'flex',
      flex: 1,
      p: section,
      [ theme.breakpoints.down('sm') ]: {
        p: lg
      }
    },
    licenceWrapper: {
      flexDirection: 'row',
      alignItems: 'start'
    },
    reportWrapper: {
      pl: sm
    },
    dummyPlaceholder: {
      pb: xs,
      fontWeight: Dimens.fontWeight.semiBold,
      visibility: 'hidden'
    },
    footer: {
      // borderTop: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      py: xl,
      bgcolor: theme.palette.background.paper,
      position: 'sticky',
      textAlign: 'end',
      [ theme.breakpoints.down('sm') ]: {
        textAlign: 'center',
        display: 'flex',
        '& button': {
          flex: 1
        }
      }
    },
    icon: {
      fontSize: `${Dimens.icon.sm} !important`
    },
    form: {
      flex: 1,
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    },
    formContent: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      flex: 1
    },
    editText: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        ...commonStyles.smIcon,
        mr: base
      }
    },
    multiBtnWrapper: {
      pt: lg,
      display: 'flex',
      justifyContent: 'space-between'
    }
  };

  return styles;
};

export default useStyles;