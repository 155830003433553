/* eslint-disable */

import React, { createContext, useReducer } from 'react';
import organizationReducer, { orgInitialState } from '../data/organization/reducer';

import AppState from '../types/states/app-state';
import useCombinedReducers from 'use-combined-reducers';

interface IContextProps {
  state: AppState;
  dispatch: (action: any) => void;
}

export const Store = createContext({} as IContextProps);

/**
 * This file defines the application global state management using React Context API.
 * 
 * Components can access and update the state using the provided context and reducer.
 */
export const StoreProvider = (props: any) => {
  const [ state, dispatch ] = useCombinedReducers<any, any>({
    orgState: useReducer(organizationReducer, orgInitialState),
  });
  const value = { state, dispatch };

  return <Store.Provider value={value}>{props.children}</Store.Provider>;
};
