const DE = {
  logout: 'Ausloggen',
  loading: 'Wird geladen...',
  error: 'Fehler',
  welcomeBack: 'Willkommen zurück',
  signInMsg: 'Melden Sie sich mit den Daten an, die Sie bei der Registrierung eingegeben haben.',
  email: 'E-Mail',
  password: 'Passwort',
  forgotPwd: 'Passwort vergessen?',
  signIn: 'Anmelden',
  emailRequired: 'E-Mail ist erforderlich.',
  pwdRequired: 'Passwort ist erforderlich.',
  emailInvalid: 'E-Mail ist ungültig.',
  verifyYou: 'Überprüfen Sie, ob Sie es sind',
  verifyCodeMsg: 'Bitte geben Sie den 6-stelligen Code ein, den wir an ',
};

export default DE;