import * as CryptoJS from 'crypto-js';

/**
 * Utility class for encryption and decryption using AES algorithm.
 * This class depends on the external library 'crypto-js'.
 */
export default class CryptoUtil {

  /**
   * Encrypts a string using AES algorithm with CBC mode and PKCS7 padding.
   * 
   * @param {string} value The value to encrypt.
   * @param {string} secret The secret key for encryption.
   * @param {string} iv The initialization vector for CBC mode.
   * @returns {string} The encrypted string.
   */
  static encryptAES(value: string, secret: string, iv: string): string {
    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value),
      CryptoJS.enc.Utf8.parse(secret),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
    );

    return encrypted.toString();
  }

  /**
   * Decrypts a string using AES algorithm with CBC mode and PKCS7 padding.
   * 
   * @param {string} value The encrypted value to decrypt.
   * @param {string} secret The secret key for decryption (same as secret used for encryption).
   * @param {string} iv The initialization vector for CBC mode (same as iv used for encryption).
   * @returns {string} The decrypted string.
   */
  static decryptAES(value: string, secret: string, iv: string): string {

    const decrypted = CryptoJS.AES.decrypt(value, CryptoJS.enc.Utf8.parse(secret), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

}