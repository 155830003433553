import { Backdrop, CircularProgress, Typography } from '@mui/material';

import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

export interface Props {
  show?: boolean;
  message?: string;
}

/**
 * Reusable component for displaying a circular progress indicator.
 * 
 * @props Custom props
 *  - show (boolean): Controls visibility of the progress bar (`true` to show, `false` to hide).
 *  - message (string, optional): Custom message to display instead of the default "loading" text.
 * 
 * @returns {JSX.Element} - The rendered circular progress bar component.
 */
const CircularProgressBar: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {props.show &&
        <Backdrop sx={styles.spinnerContainer} open={props.show || false}>
          <CircularProgress color='primary' sx={styles.spinner} />
          <Typography variant='caption'>{props.message || t('loading')}</Typography>
        </Backdrop>
      }
    </>

  );
}

export default CircularProgressBar;