import { Box, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import CircularProgressBar from '../../components/CircularProgressBar';
import CustomButton from '../../components/CustomButton';
import CustomInput from '../../components/CustomInput';
import EyeIcon from '../../components/CustomIcons/EyeOffIcon';
import EyeOffIcon from '../../components/CustomIcons/EyeIcon';
import ForgetPasswordRequest from '../../types/forget-password-verify-request';
import InlineAlert from '../../components/InlineAlert';
import PasswordRequest from '../../types/password-request';
import { PasswordSchema } from '../../utils/validation-schema';
import SignInLayout from '../../layouts/SignInLayout';
import Util from '../../utils/util';
import ValidationItem from '../../types/validation-item';
import { useLocation } from 'react-router-dom';
import { useSignInApi } from '../../data/sign-in/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Reset password screen component for setting a new password.
 *
 * This component renders within the `SignInLayout` and allows users to enter a new password.
 * It utilizes `Formik` for form management and validation. Users must enter a password that meets
 * specified complexity criteria (defined by regular expressions in `utils/constants`). Real-time
 * validation feedback is provided below the input field using the `validationList` state and
 * `Util.getPasswordValidationStatus` function. Upon submission (if valid), the component logs the
 * submitted data to the console (replace this with actual logic for resetting the password).
 *
 * @returns {JSX.Element} - The rendered reset password screen component.
 */
const ResetPasswordScreen: React.FC = () => {

	const styles = useStyles();
	const { t } = useTranslation();
	const location = useLocation();
	const forgetPasswordRequest = location.state as ForgetPasswordRequest;
	const initialPwdRequest: PasswordRequest = {
		password: ''
	};
	const [ validationList, setValidationList ] = useState<Array<ValidationItem>>(Util.getPasswordValidationStatus(''));
	const [ showPassword, setShowPassword ] = useState<boolean>(false);
	const signInApi = useSignInApi();

	/**
	 * Initial check to determine if `forgetPasswordRequest` is defined.
	 * If it is not defined, the effect exits early without performing any actions.
	 * 
	 * This effect currently does not include any further logic but serves as a placeholder
	 * for potential future updates based on the value of `forgetPasswordRequest`.
	 */
	useEffect(() => {
		if (!forgetPasswordRequest) {
			return;
		}
	}, []);

	/**
	 * Handles the submission of the password request.
	 * 
	 * This function updates the `forgetPasswordRequest` object with the new password
	 * and then calls the `performSetPassword` method from the `signInApi` to
	 * initiate the password setting process.
	 * 
	 * @param {PasswordRequest} passwordRequest - The request object containing the new password to be set.
	 */
	const onSubmit = (passwordRequest: PasswordRequest) => {
		forgetPasswordRequest.password = passwordRequest?.password ?? '';
		signInApi.performSetPassword(forgetPasswordRequest);
	};

	/**
	 * Handles text change events for the password input field.
	 *
	 * This function updates the `validationList` state with the latest validation status
	 * for the entered password using the `Util.getPasswordValidationStatus` function.
	 *
	 * @param {string} value - The new password value entered by the user.
	 */
	const onChangeText = (value: string) => {

		setValidationList(Util.getPasswordValidationStatus(value));
	};

	return (
		<SignInLayout>
			<Box sx={styles.innerWrapper}>
				<Stack sx={styles.header}>
					<Typography variant='h1'>{t('updatePwd')}</Typography>
				</Stack>
				<Formik
					validateOnMount
					validationSchema={PasswordSchema}
					initialValues={initialPwdRequest}
					validateOnChange
					onSubmit={values => onSubmit(values)}>
					{({ isValid, values }) => (
						<Form>
							<InlineAlert message={signInApi.state.apiStatus?.error} />
							<Field
								name='password'
								label={t('newPwd')}
								placeholder={t('password')}
								component={CustomInput}
								onChangeText={onChangeText}
								validationList={validationList}
								hasFooter
								type={showPassword ? 'text' : 'password'}
								endAdornment={
									values.password &&
									<InputAdornment position='end'>
										<IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
											{showPassword ? <EyeOffIcon sx={styles.icon} /> : <EyeIcon sx={styles.icon} />}
										</IconButton>
									</InputAdornment>
								}
							/>
							<Box sx={styles.spacer} />
							<CustomButton
								type='submit'
								title={t('save')}
								color='primary'
								disabled={!isValid}
								fullWidth />
						</Form>
					)}
				</Formik>
			</Box>
			<CircularProgressBar show={signInApi.state.apiStatus?.isLoading} />
		</SignInLayout>
	);
};

export default ResetPasswordScreen;
