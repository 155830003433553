import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the SignInLayout component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    wrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      bgcolor: theme.palette.primary.light,
      flexGrow: 1
    },
    langWrapper: {
      position: 'absolute',
      top: Dimens.spacing.base,
      right: Dimens.spacing.base,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  };

  return styles;
};

export default useStyles;