const APIConfig = {
  baseURL: process.env.REACT_APP_API_URL || '',
  relPath: '/api/admin',
  login: '/signin',
  verification: '/verification',
  refreshToken: '/oauth2/token',
  logOut: '/logout',
  organization: '/organizations',
  features: '/permissions',
  licence: '/licence',
  forget: '/forget',
  passVerification: '/pass-verification',
  setPassword: '/set'
}

export default APIConfig
