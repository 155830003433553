import { Avatar, ListItemAvatar, ListItemText } from '@mui/material';

import { DATE_PATTERN_DD_MM_YYYY_DOT } from '../../../../utils/constants';
import ListItemButton from '@mui/material/ListItemButton';
import OrganizationDetail from '../../../../types/organization-detail';
import React from 'react';
import Typography from '@mui/material/Typography';
import Util from '../../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  organization: OrganizationDetail;
  onItemSelected: (organization: OrganizationDetail) => void;
}

/**
 * Organization List Item Component
 * 
 * This component renders a single organization list item for the organization list view.
 * It displays the organization's name, a formatted expiry date (if available), and an indication of its active/draft status.
 *
 * @props { Props } - The component properties.
 * @property { OrganizationDetail } organization - The organization data object to be displayed.
 * @property { (organization: OrganizationDetail) => void } onItemSelected - Callback function triggered on item selection.
 *
 * @returns {JSX.Element} - The rendered JSX element representing the organization list item.
 */
const OrganizationListItem: React.FC<Props> = (props: Props) => {

  const styles = useStyles({status: props.organization.status});
  const { t } = useTranslation();

  /**
   * Formats the provided date object into a human-readable string with an "expires" label.
   * 
   * @param {string} dateString - The date object to be formatted.
   * @returns {string} - The formatted date string with expiry label (or empty string if no date provided).
   */
  const getExpiryDate = (dateString?: string): string => {
    if (!dateString) {
        return '';
    }
    const date = new Date(dateString);
    
    return `${t('expires')} ${Util.formatUTCtoLocal(date, DATE_PATTERN_DD_MM_YYYY_DOT)}`;
}

  return (
    <ListItemButton onClick={() => props.onItemSelected(props.organization)} sx={styles.listItem}>
      <ListItemAvatar sx={styles.avatarWrapper}>
        <Avatar sx={styles.avatar}>
          <Typography variant='p3' >
            {props.organization.name.substring(0, 1).toLocaleUpperCase()}
          </Typography>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={styles.textWrapper}
        primary={
          <Typography variant='h6' sx={[styles.textEllipsis, styles.primaryText]}>
            {props.organization.name}
          </Typography>
        }
        secondary={
          <Typography variant='footer' sx={[styles.textEllipsis, styles.secondaryText]}>
            {getExpiryDate(props.organization.expiryAt)}
          </Typography>
        } />
      <Typography
        variant='caption' sx={{ 
          ...styles.status,
          ...styles.statusDynamic      
        }}>
        {t(props.organization.status.toLowerCase())}
      </Typography>
    </ListItemButton>
  );
};

export default OrganizationListItem;