import * as yup from 'yup';

import { Field, Form, Formik } from 'formik';

import { Box } from '@mui/material';
import CircularProgressBar from '../../components/CircularProgressBar';
import CustomButton from '../../components/CustomButton';
import CustomInput from '../../components/CustomInput';
import ForgotPasswordRequest from '../../types/forgot-password-request';
import InlineAlert from '../../components/InlineAlert';
import LogoHeader from '../../components/LogoHeader';
import React from 'react';
import SignInLayout from '../../layouts/SignInLayout';
import { useSignInApi } from '../../data/sign-in/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Forgot password screen component for requesting a password reset.
 *
 * This component renders within the `SignInLayout` and displays a logo, heading,
 * sub-heading, email input field, and a submit button. It utilizes `Formik` for
 * form management and validation. Users can enter their email address to initiate
 * the password reset process. Upon submission (if valid), the component logs the
 * submitted data to the console (replace this with actual reset request logic).
 *
 * @returns {JSX.Element} - The rendered reset password screen component.
 */
const ForgotPasswordScreen: React.FC = () => {

  const styles = useStyles();
  const { t } = useTranslation();
  const signInApi = useSignInApi();
  const VALIDATION_SCHEMA = yup.object().shape({
    loginId: yup.string().trim()
      .required(t('emailRequired'))
      .email(t('emailInvalid'))
  });
  const initialResetRequest: ForgotPasswordRequest = {
    loginId: '',
  };

  /**
 * Handles form submission for the forgot password request.
 *
 * This function is called when the form is submitted and validates if the form data is valid.
 * If valid, it sends a email validation request to the backend.
 *
 * @param {SignInRequest} request - The submitted login request data containing the email address.
 */
  const onSubmit = (request: ForgotPasswordRequest) => {
    signInApi.performForgetPassword(request);
  };

  return (
    <SignInLayout>
      <Box sx={styles.innerWrapper}>
        <LogoHeader heading={t('resetPwd')} subHeading={t('resetPwdMsg')} />
        <Formik
          validateOnMount
          validationSchema={VALIDATION_SCHEMA}
          initialValues={initialResetRequest}
          onSubmit={values => onSubmit(values)}>
          {({ isValid }) => (
            <Form>
              <InlineAlert message={signInApi.state.apiStatus?.error} />      
              <Field name='loginId' placeholder={t('email')} component={CustomInput} />
              <Box sx={styles.spacer} />
              <CustomButton type='submit' title={t('signIn')} color='primary' disabled={!isValid} fullWidth />
            </Form>
          )}
        </Formik>
      </Box>
      <CircularProgressBar show={signInApi.state.apiStatus?.isLoading} />
    </SignInLayout>
  );
};

export default ForgotPasswordScreen;
