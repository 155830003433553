import Dimens from '../../../../../theme/dimens';
import Feature from '../../../../../types/feature';
import ThemeUtil from '../../../../../theme/theme-util';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the FeatureCard component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = (feature: Feature) => {

  const theme = useTheme();
  const { xxs, xs, lg, xl, section } = Dimens.spacing;

  const styles = {
    itemWrapper: {
      display: 'flex',
      flexDirection: 'column',
      p: `${section} ${xl}  ${xl}  ${xl}`,
      border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      borderTop: feature.value ? `${xs} solid ${feature.disabled ? theme.palette.primary.light : theme.palette.primary.main}` : 'undefined',
      borderRadius: Dimens.radius.md,
      alignItems: 'center',
      color: (feature.value && !feature.disabled) ? theme.palette.text.primary : theme.palette.text.secondary,
      'svg': {
        fontSize: Dimens.icon.xl
      }
    },
    label: {
      mt: xl,
      mb: lg,
      fontWeight: Dimens.fontWeight.semiBold
    },
    iosSwitch: {
      width: Dimens.switch.width,
      height: Dimens.switch.height,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.main,
            opacity: 1,
            border: 0
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.light,
            opacity: 0.9
          },
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: theme.palette.background.paper
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: ThemeUtil.isLightTheme(theme) ? 0.7 : 0.3,
        },
      },
      '& .MuiSwitch-thumb': {
        color: theme.palette.background.paper,
        boxSizing: 'border-box',
        width: Dimens.switch.thumbSize,
        height: Dimens.switch.thumbSize,
        m: xxs
      },
      '& .MuiSwitch-track': {
        borderRadius: Dimens.radius.xl,
        backgroundColor: theme.palette.text.secondary,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500
        }),
      }
    }
  };

  return styles;
};

export default useStyles;