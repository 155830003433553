import BearerToken from '../../types/bearer-token';

export const INIT_API = 'INIT_API';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const API_FAILURE = 'API_FAILURE';

export interface InitApiAction {
  type: typeof INIT_API
}

export interface SignInSuccessAction {
  type: typeof SIGN_IN_SUCCESS,
  payload: BearerToken
}

export interface SignOutSuccessAction {
  type: typeof SIGN_OUT_SUCCESS,
  payload: boolean
}

export interface ApiFailureAction {
  type: typeof API_FAILURE,
  payload: string
}

export type SignInActionTypes = InitApiAction | SignInSuccessAction | SignOutSuccessAction | ApiFailureAction;