import { useEffect } from 'react';

/**
 * Custom hook to notify parent component when Formik's isValid changes.
 *
 * @param isValid - Formik's isValid value.
 * @param onValidityChange - Callback function to notify parent.
 */
const useFormikValidity = (isValid: boolean, onValidityChange: (isValid: boolean) => void) => {
  useEffect(() => {
    onValidityChange(isValid);
  }, [isValid, onValidityChange]);
};

export default useFormikValidity;