import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the ValidationItemView component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

	const theme = useTheme();
	
	const styles = {
		validationItem: {
			m: 0,
			pt: Dimens.spacing.base
		},
		checkbox: {
			p: `0 ${Dimens.spacing.xs} 0 0`
		},
		iconActive: {
			color: theme.palette.success.light,
			fontSize: Dimens.icon.sm
		},
		iconInactive: {
			color: theme.palette.text.secondary,
			fontSize: Dimens.icon.sm
		},
		textActive: {
			color: theme.palette.text.primary
		},
		textInactive: {
			color: theme.palette.text.secondary
		}
	};

	return styles;
};

export default useStyles;