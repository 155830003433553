import Dimens from '../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the OrganizationListScreen component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const { xxs, base, lg, section, container } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    wrapper: {
      display: 'flex',
      height: 'inherit',
      position: 'relative'
    },
    innerWrapper: {      
      flex: 1,
      minHeight:0,
      overflowY:'auto',
      m: section,
      p: `${xxs} 0 ${section} 0`,
      borderRadius: Dimens.radius.lg,
      bgcolor: theme.palette.background.paper,
      [theme.breakpoints.down('sm')]: {
        m: lg,
        p: `${xxs} 0 ${section} 0`
      }
    },
    fab: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      m: container,
      '.MuiSvgIcon-root': {
        fontSize: Dimens.icon.lg
      },
      [theme.breakpoints.down('sm')]: {
        m: lg,
      }
    },
    list: {
      flex:1,
      p: `${base} 0 ${section} 0`,
      [theme.breakpoints.down('sm')]: {
        p: `${xxs} 0 ${lg} 0`,
      }
    }
  };

  return styles;
};

export default useStyles;