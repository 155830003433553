import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DATE_PICKER_FORMAT_DD_MM_YYYY, OrgStatus } from '../../../../utils/constants';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';

import ActivateOrganizationImage from '../../../../assets/org_activate.svg';
import AlertDialog from '../../../../components/AlertDialog';
import CircularProgressBar from '../../../../components/CircularProgressBar';
import CustomButton from '../../../../components/CustomButton';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import CustomInput from '../../../../components/CustomInput';
import CustomInputSelect from '../../../../components/CustomInputSelect';
import DeactivateOrganizationImage from '../../../../assets/org_deactivate.svg';
import InlineAlert from '../../../../components/InlineAlert';
import { OrgDetailSchema } from '../../../../utils/validation-schema';
import Organization from '../../../../types/organization';
import OrganizationDetail from '../../../../types/organization-detail';
import PencilIcon from '../../../../components/CustomIcons/PencilIcon';
import { Store } from '../../../../store/store';
import useFormikValidity from '../../../../hooks/use-formik-validity';
import { useOrganizationApi } from '../../../../data/organization/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface AlertInfo {
	title: string;
	message: string;
	actionText: string;
	titleImage: string;
}

interface Props {
	isReadMode: boolean;
	formikRef: React.RefObject<FormikProps<OrganizationDetail>>;
	organization: Organization;
	handleOrgDetailChange: (orgDetail: OrganizationDetail) => void;
	changeOrgStatus: (status: OrgStatus) => void;
	onValidityChange: (isValid: boolean) => void;
	toggleReadMode: (isReadMode: boolean) => void;
}

/**
 * Detail Tab for Organization Management
 *
 * This component renders the detail tab within the organization management view.
 * It allows editing organization details like name, license, support email, and description.
 * It also displays a button to activate/deactivate the organization based on its current status.
 * 
 * @props { Props } - The component properties.
 * @property { OrganizationDetail } orgDetail - The organization data object to be displayed and edited.
 * @property { (orgDetail: OrganizationDetail) => void } handleOrgDetailChange - Callback function to handle changes in organization details.
 *
 * @returns {JSX.Element} - The rendered JSX element for the detail tab.
 */
const DetailTab: React.FC<Props> = (props: Props) => {

	const styles = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { t } = useTranslation();
	const [showAlert, setShowAlert] = useState<boolean>(false);
	const [orgDetail, setOrgDetail] = useState<OrganizationDetail>(props.organization?.details);
	const { state } = React.useContext(Store);
	const orgApi = useOrganizationApi();

	/**
	 * Updates the `orgDetail` state with the `details` property from the `props.organization` object.
	 * This effect runs whenever the `props.organization` prop changes.
	 */
	useEffect(() => {
		if (props.organization && props.organization.details) {
			setOrgDetail(props.organization.details);
			// props.organization.details.id ? setprops.isReadMode(true) : setprops.isReadMode(false);
		}
	}, [props.organization, props.organization.details.status]);

	/**
	 * Constructs the alert information based on the provided organization status.
	 * 
	 * @param {string} status - The organization status (Active, Inactive, Draft).
	 * @returns {AlertInfo} - An object containing information for the alert dialog.
	 */
	const getAlertInfo = (status?: string): AlertInfo => {
		switch (status) {
			case OrgStatus.Active:
				return {
					title: t('deactivateOrg'),
					message: t('deactivateOrgMsg'),
					actionText: t('deactivate'),
					titleImage: DeactivateOrganizationImage
				};
			case OrgStatus.Inactive:
				return {
					title: t('reactivateOrg'),
					message: t('reactivateOrgMsg'),
					actionText: t('reactivate'),
					titleImage: ActivateOrganizationImage
				};
			case OrgStatus.Draft:
				return {
					title: t('activateOrg'),
					message: t('activateOrgMsg'),
					actionText: t('activate'),
					titleImage: ActivateOrganizationImage
				};
			default:
				return {
					title: t('activateOrg'),
					message: t('activateOrgMsg'),
					actionText: t('activate'),
					titleImage: ActivateOrganizationImage
				};
		}
	};

	/**
	 * Handles the activation click event for the organization.
	 *
	 * @returns {void} - This function does not return a value; it performs side effects related to organization activation.
	 */
	const onActivateClick = () => {
		props.changeOrgStatus(OrgStatus.Active);
		setShowAlert(false);
	};

	return (
		<Grid sx={styles.wrapper}>
			<Grid sx={styles.innerWrapper}>
				<Formik
					innerRef={props.formikRef}
					enableReinitialize
					validateOnMount
					validateOnChange
					validationSchema={OrgDetailSchema}
					initialValues={orgDetail}
					onSubmit={_ => { () => {/* Do Nothing */ } }}>
					{({ isValid }) => {
						useFormikValidity(isValid, props.onValidityChange);
						return (
							<Form style={styles.form as React.CSSProperties}>
								<Box sx={styles.formContent}>
									<InlineAlert message={orgApi.state.orgState.apiStatus?.error} />
									<Field
										name='name'
										label={t('orgName')}
										placeholder={t('name')}
										readOnly={props.isReadMode}
										component={CustomInput}
									/>
									{(orgDetail.id && props.isReadMode) && 
										<Field
											name='domain'
											label={t('domain')}
											readOnly={props.isReadMode}
											placeholder={t('domain')}
											component={CustomInput}
										/>
									}
									<Stack sx={styles.licenceWrapper}>
										<Field
											name='licenceId'
											label={t('licence')}
											placeholder={t('pleaseSelect')}
											disabled={props.isReadMode}
											component={CustomInputSelect}
											menu={state.orgState.licenseList}
										/>
										{props.isReadMode &&
											<Stack sx={styles.reportWrapper}>
												<Typography variant='p1' sx={styles.dummyPlaceholder}>
													{isMobile ? t('report') : t('createReport')}
												</Typography>
												<CustomButton title={isMobile ? t('report') : t('createReport')} color='primary' />
											</Stack>
										}
									</Stack>
									<Field
										name='expiryAt'
										label={t('expiryDate')}
										placeholder={DATE_PICKER_FORMAT_DD_MM_YYYY}
										format={DATE_PICKER_FORMAT_DD_MM_YYYY}
										component={CustomDatePicker}
										readOnly={props.isReadMode}
									/>
									<Field
										name='supportEmail'
										label={t('supportEmail')}
										placeholder={t('emailAddress')}
										readOnly={props.isReadMode}
										component={CustomInput}
									/>
									<Field
										name='pinCode'
										type='number'
										label={t('telephonyPinCode')}
										subLabel={t('optional')}
										placeholder={t('telephonyPinCode')}
										readOnly={props.isReadMode}
										component={CustomInput}
									/>
									<Field
										multiline
										name='description'
										label={t('description')}
										subLabel={t('optional')}
										placeholder={t('description')}
										readOnly={props.isReadMode}
										component={CustomInput}
									/>
									{(orgDetail.id && props.isReadMode) &&
										<Typography variant='p1' sx={styles.editText} onClick={() => props.toggleReadMode(!props.isReadMode)}>
											<PencilIcon />
											{t('editDetails')}
										</Typography>}
								</Box>
								{(orgDetail.id && props.isReadMode && orgDetail.status === OrgStatus.Draft) &&
									<Box sx={styles.multiBtnWrapper}>
										<CustomButton
											color='primary'
											fullWidth
											title={getAlertInfo(orgDetail.status).actionText}
											// destructive={orgDetail.status === OrgStatus.Active} // hide deactivate button for now
											onClick={() => setShowAlert(true)}
										/>
									</Box>
								}
							</Form>
						)
					}}
				</Formik>
			</Grid>
			<CircularProgressBar show={orgApi.state.orgState.apiStatus?.isLoading} />
			<AlertDialog
				isLarge
				open={showAlert}
				title={getAlertInfo(orgDetail.status).title}
				message={getAlertInfo(orgDetail.status).message}
				titleIcon={getAlertInfo(orgDetail.status).titleImage}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setShowAlert(false)}
				primaryLabel={getAlertInfo(orgDetail.status).actionText}
				onPrimaryAction={onActivateClick}
				isDestructive={orgDetail.status === OrgStatus.Active}
				onClose={() => setShowAlert(false)}
			/>
		</Grid>
	);
};

export default DetailTab;