import {
  Box,
  Divider,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  Typography,
} from '@mui/material';

import CaretDownIcon from '../CustomIcons/CaretDownIcon';
import CheckIcon from '../CustomIcons/CheckIcon';
import MenuData from '../../types/ui/menu-data';
import React from 'react';
import useStyles from './styles';

/**
 * Interface defining props specific to the CustomSelect component.
 * 
 * @interface Props
 */
type Props = {
  /**
   * Array of menu data objects containing `id` and `value` properties for options.
   */
  menu: Array<MenuData>;
  /**
   * Optional React node to be used as a left icon for the select.
   */
  leftIcon?: React.ReactNode;
  /**
   * Boolean prop to control whether the select has a background color.
   */
  hasBackground?: boolean;
  /**
   * Boolean prop to control whether to show a check icon next to the selected option.
   */
  showCheckedIcon?: boolean;
} & SelectProps;

/**
 * Custom select component built with Material-UI's Select and styled with custom classes.
 *
 * This component allows for customization of the select menu through props. It accepts
 * menu data, an optional left icon, and additional Select properties. It renders a styled
 * select component with a custom icon and options populated from the provided menu data.
 *
 * @param {Props} props - Component properties defining menu data, left icon, and Select props.
 *
 * @returns {JSX.Element} - The rendered custom select component.
 */
const CustomSelect: React.FC<Props> = (props: Props) => {

  const { leftIcon, hasBackground, showCheckedIcon, ...rest } = props;
  const styles = useStyles(hasBackground);

  /**
   * Function to render the selected value in the select component.
   * 
   * It finds the menu item matching the selected value and returns its value as a Typography component.
   * If no matching item is found, it returns null.
   *
   * @param {string} value - The selected value of the select.
   * 
   * @returns {JSX.Element | null} - Typography component with the selected value or null.
   */
  const renderValue = (value: string) => {
    const selectedItem = props.menu.find((item) => item.id === value);

    return selectedItem ? <Typography variant={'p1'}>{selectedItem.value}</Typography> : null;
  };

  return (
    <Stack sx={styles.wrapper}>
      {leftIcon &&
        <Box sx={styles.leftIconWrapper}>{leftIcon}</Box>
      }
      <Select
        {...rest}
        sx={styles.select}
        renderValue={value => renderValue(value as string)}
        IconComponent={(props) => <CaretDownIcon sx={styles.caretIcon} {...props} />}
        MenuProps={{
          disableAutoFocusItem: true,
          MenuListProps: {
            sx: styles.menuList
          },
          slotProps: {
            paper: {
              sx: styles.paper
            }
          }
        }}
      >
        {props.menu.flatMap((option, index) => [
          <MenuItem key={option.id} value={option.id} sx={styles.menuItem}>
            <Typography variant={'p1'}>{option.value}</Typography>
            {(showCheckedIcon && props.value === option.id) &&
              <CheckIcon sx={styles.icon} />
            }
          </MenuItem>,
          index !== props.menu.length - 1 && (
            <Divider key={`divider-${index}`} sx={styles.divider} />
          ),
        ])}
      </Select>
    </Stack>
  );
};

export default CustomSelect;
