const ES = {
  logout: 'Cerrar sesión',
  loading: 'Cargando...',
  error: 'Error',
  welcomeBack: 'Bienvenido de nuevo',
  signInMsg: 'Inicia sesión con los datos que ingresaste durante tu registro.',
  email: 'Correo electrónico',
  password: 'Contraseña',
  forgotPwd: '¿Olvidaste tu contraseña?',
  signIn: 'Iniciar sesión',
  emailRequired: 'El correo electrónico es obligatorio.',
  pwdRequired: 'La contraseña es requerida.',
  emailInvalid: 'Correo electrónico inválido.',
  verifyYou: 'Verifica que seas tú',
  verifyCodeMsg: 'Ingresa el código de 6 dígitos que te enviamos a ',
};

export default ES;