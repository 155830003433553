/**
 * useStyles custom hook generates MUI theme dependent styles for the LogsTab component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const styles = {
    wrapper: {
      display: 'flex',
      height: 'inherit'
    },
    innerWrapper: {
      flex: 1
    }
  };

  return styles;
};

export default useStyles;